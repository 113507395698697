.wysiwyg-wrapper {
  width: 100%;
  border: 1px solid lightgray;
}

.wysiwyg-editor {
  height: 500px;
}

@media (max-width: 426px) and (min-width: 0) {
  .rdw-emoji-modal {
    left: -150px;
  }
}

@media (max-width: 717px) and (min-width: 622px) {
  .rdw-emoji-modal {
    left: -169px;
  }
}

@media (min-width: 1086px) {
  .rdw-emoji-modal {
    left: -189px;
  }
}
